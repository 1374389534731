// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}visit/task/pageList`
// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList?ismodal=1`


const updateURL = `${API_CONFIG.butlerBaseURL}visit/plan/update`


const saveQueURL = `${API_CONFIG.butlerBaseURL}visit/task/execute`

const invalidTaskURL = `${API_CONFIG.butlerBaseURL}visit/task/batchInvalid`

const generateTaskURL = `${API_CONFIG.butlerBaseURL}visit/plan/generate`

const getHouseListURL = `${API_CONFIG.shopBaseURL}communityRoom/listCommunityRoom`

const getUserListURL = `${API_CONFIG.butlerBaseURL}user/search/community/username`

const getTaskDetailURL = `${API_CONFIG.butlerBaseURL}visit/task/detail`
const uploadURL = `${API_CONFIG.uploadURL}/fileUploadNoLimit`

const getUserRoomsListURL = `${API_CONFIG.butlerBaseURL}orgInfo/house/by/communityId`

// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}visit/task/export`
export {
  getListURL,
  getCommunityListURL,
  updateURL,
  saveQueURL,
  invalidTaskURL,
  generateTaskURL,
  getHouseListURL,
  getUserListURL,
  getTaskDetailURL,
  uploadURL,
  exportListURL,
  getUserRoomsListURL
}
