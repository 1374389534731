<template>
  <div class="visitOwnerConfigList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="new"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      isMultiSelect
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="导出" @click="exportList"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="计划名称" v-model="searchParams.planName"></v-input>
        <v-select2 v-model="searchParams.communityId" label="所属项目"  v-bind="communityIdConfig"></v-select2>
        <v-select2 label="房号" :disabled="!(searchParams.communityId && searchParams.communityId.length)" v-model="searchParams.houseId" placeholder="查询房号" v-bind="roomIdConfig" :subjoin="communityIdParams"></v-select2>
        <v-select  label="拜访方式" v-model="searchParams.visitWay" :options="visitWayList"></v-select>
        <v-select2
          label="负责人"
          v-model="searchParams.chargeUserId"
          v-bind="userBind"
          :width="220"
          :subjoin="{ communityId: searchParams.communityId }"
          :disabled="!searchParams.communityId || searchParams.communityId.length === 0"
        />
        <v-select  label="拜访状态" v-model="searchParams.visitStatus" :options="statusOps"></v-select>
        <v-datepicker
          label="实际拜访时间"
          type="rangedatetimer"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
          :minDate="minDate"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.visitStatus === 1" text="查看" type="text" @click="lookTaskDetail(scope.row)"></v-button>
        <v-button v-if="scope.row.visitStatus === 0" text="执行" permission="execute" type="text" @click="execute(scope.row)"></v-button>
        <v-button v-if="scope.row.visitStatus === 0" text="作废" permission="invalid" type="text" @click="changeState(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量作废"  @click="batchRemove(scope.selectedData)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  getUserListURL,
  invalidTaskURL,
  exportListURL,
  getUserRoomsListURL
} from './api'
import { statusOps, statusMap,visitTypeList,visitTypeMap,visitWayMap,visitWayList} from './map'
import { communityParams, tenantParams } from 'common/select2Params'

export default {
  name: 'visitTaskList',
  data () {
    return {
      minDate:new Date(),
      exportListURL,
      userBind: {
        searchUrl:getUserListURL,
        request: {
          text: 'username',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      searchUrl: getListURL,
      statusOps,
      visitTypeList,
      visitWayList,
      communityParams,
      tenantParams,
      roomIdConfig: {
        searchUrl: getUserRoomsListURL,
        request: {
          text: 'address',
          value: 'id'
        },
        response: {
        }
      },
      communityIdConfig: communityParams,
      searchParams: {
        planName: '',
        communityId: undefined,
        visitStatus:undefined,
        visitWay:undefined
      },
      headers: [
        {
          prop: 'planName',
          label: '计划名称'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'visitType',
          label: '拜访类型',
          formatter (row) {
            return visitTypeMap[row.visitTempId]
          }
        },
        {
          prop: 'houseName',
          label: '拜访房号'
        },
        {
          prop: 'visitUserName',
          label: '拜访人'
        },
        {
          prop: 'visitWay',
          label: '拜访方式',
          formatter (row) {
            return visitWayMap[row.visitWay]
          }
        },
        {
          prop: 'startTime',
          label: '计划拜访时间',
          formatter (row) {
            return row.startTime + '~' + row.endTime
          }
        },
        {
          prop: 'visitTime',
          label: '实际拜访时间'
        },
        {
          prop: 'executeUserName',
          label: '实际拜访人'
        },
        {
          prop: 'chargeUserName',
          label: '负责人'
        },
        {
          prop: 'visitStatus',
          label: '状态',
          formatter (row) {
            return statusMap[row.visitStatus]
          }
        }

      ]
    }
  },
  created () {

  },
  mounted() {
    this.query = this.subPageType == 'drawer' ? this.editQuery : this.$route.query
    let { planName,communityId } = this.query
    this.searchParams.planName = planName;
    this.searchParams.communityId = communityId;
    if (!communityId) {
      this.searchParams.houseId = undefined;
    }
  },
  computed: {
    communityIdParams () {
      return {
        communityId: this.searchParams.communityId,
      }
    },
  },
  methods: {
    create () {
      this.$router.push({
        name: 'VisitPlanConfigForm'
      })
    },
    lookTaskDetail (row) {
      this.$router.push({
        name: 'VisitTaskForm',
        query: {
          id: row.id,
          isLook: true
        }
      })
    },
    execute (row) {
      const startTime = Date.parse(row.startTime);
      if (startTime > new Date()) {
        this.$message({
          type: 'warning',
          message: '未到计划开始时间，无法执行！',
          center: true
        })
        return
      }
      this.$router.push({
        name: 'VisitTaskForm',
        query: {
          id: row.id,
          isLook: false
        }
      })
    },
    exportList() {
      this.$axios({
        url: exportListURL,
        method: 'get',
        data: this.searchParams
      }).then(res => {
        console.log(res)
        if (res.status === 100) {
          this.$message({
            type: 'success',
            message: '导出成功',
            center: true
          })
        }
      })
    },
    async batchRemove(data) {
      console.log(data)
      let msg = '是否确认作废'
      let isOk = await this.$confirm(msg)
      let param = {
        ids: data.ids
      }
      isOk && this.$axios({
        url: invalidTaskURL,
        method: 'post',
        data: param
      }).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    async changeState (row) {
      let msg = '是否确认作废'
      let isOk = await this.$confirm(msg)
      let data = {
        ids: [row.id]
      }
      isOk && this.$axios({
        url: invalidTaskURL,
        method: 'post',
        data: data
      }).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    }
  }
}
</script>
