var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "visitOwnerConfigList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          exportMethod: "new",
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          isMultiSelect: "",
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "导出" },
                  on: { click: _vm.exportList },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "计划名称" },
                  model: {
                    value: _vm.searchParams.planName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "planName", $$v)
                    },
                    expression: "searchParams.planName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityIdConfig,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "房号",
                        disabled: !(
                          _vm.searchParams.communityId &&
                          _vm.searchParams.communityId.length
                        ),
                        placeholder: "查询房号",
                        subjoin: _vm.communityIdParams,
                      },
                      model: {
                        value: _vm.searchParams.houseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "houseId", $$v)
                        },
                        expression: "searchParams.houseId",
                      },
                    },
                    "v-select2",
                    _vm.roomIdConfig,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "拜访方式", options: _vm.visitWayList },
                  model: {
                    value: _vm.searchParams.visitWay,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "visitWay", $$v)
                    },
                    expression: "searchParams.visitWay",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "负责人",
                        width: 220,
                        subjoin: { communityId: _vm.searchParams.communityId },
                        disabled:
                          !_vm.searchParams.communityId ||
                          _vm.searchParams.communityId.length === 0,
                      },
                      model: {
                        value: _vm.searchParams.chargeUserId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "chargeUserId", $$v)
                        },
                        expression: "searchParams.chargeUserId",
                      },
                    },
                    "v-select2",
                    _vm.userBind,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "拜访状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.visitStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "visitStatus", $$v)
                    },
                    expression: "searchParams.visitStatus",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "实际拜访时间",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                    minDate: _vm.minDate,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                scope.row.visitStatus === 1
                  ? _c("v-button", {
                      attrs: { text: "查看", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.lookTaskDetail(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.visitStatus === 0
                  ? _c("v-button", {
                      attrs: {
                        text: "执行",
                        permission: "execute",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.execute(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.visitStatus === 0
                  ? _c("v-button", {
                      attrs: {
                        text: "作废",
                        permission: "invalid",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeState(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "批量作废" },
                  on: {
                    click: function ($event) {
                      return _vm.batchRemove(scope.selectedData)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }